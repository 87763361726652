import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import {
  createBemBlockBuilder,
  IndeterminateSpinner,
} from '@ebsco-ui/ebsco-ui';

import { parseMarcRecord } from '@app/components';
import { axios } from '@app/utils';
import { sharedMessages } from '@app/translations';

import './QuickMarkView.scss';

const cnBem = createBemBlockBuilder(['quickMarkViewTable']);

export const QuickMarkView: FC = () => {
  const { $t } = useIntl();
  const { id } = useParams();

  const marcFieldsQuery = useQuery(['marcFields', id], () =>
    axios
      .get(`/opac-inventory/source-records/${id}`)
      .then(response => parseMarcRecord(response.data?.parsedRecord?.content))
  );

  return (
    <>
      {marcFieldsQuery.isLoading && (
        <div data-testid="spinner">
          <IndeterminateSpinner
            level="component"
            className={cnBem('__spinner')}
          />
        </div>
      )}
      {marcFieldsQuery.data && (
        <div className={cnBem('__wrapper')}>
          <table aria-label={$t(sharedMessages.staffView)}>
            <tbody>
              {marcFieldsQuery.data.map(([name, value]) => (
                <tr key={`${name}${value}`}>
                  <td>{name}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
