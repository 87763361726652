import { FC, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import { Button, Drawer } from '@ebsco-ui/ebsco-ui';

import {
  Image,
  AccountInfo,
  NavigationPanel,
  LinkElement,
  ConditionalComponent,
} from '@app/components';
import { useBranding } from '@app/hooks';
import { BREAKPOINT, FEATURE, ROUTE } from '@app/constants';

import css from './Navigation.module.scss';

export const Navigation: FC = () => {
  const { logo } = useBranding();
  const { $t } = useIntl();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.SCREEN.TABLET_LG - 1}px)`,
  });

  const burgerButtonId = 'burgerButton';

  const showDrawer = useCallback(
    (isOpen: boolean) => () => setDrawerOpen(isOpen),
    []
  );

  return (
    <div className={css.wrapper} data-testid="navigation-wrapper">
      <div className={cn(css.container, 'container')}>
        <LinkElement
          url={logo.link ?? ROUTE.search}
          data-testid="logo"
          className={css.logoLink}
        >
          <Image className="logo" src={logo.url} altText={logo.altText} />
          <span className={css.catalogLabel}>
            {$t({
              id: 'navigation.catalog',
              defaultMessage: 'Catalog',
            })}
          </span>
        </LinkElement>
        {isMobile ? (
          <div>
            <Button
              id={burgerButtonId}
              className={css.burgerButton}
              styleType="flat-icon"
              aria-label={$t({
                id: 'navigation.openNavigationMenu',
                defaultMessage: 'Open the navigation menu',
              })}
              onClick={showDrawer(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
            <Drawer
              drawerTag="section"
              className={css.drawer}
              position="start"
              titleElementId={burgerButtonId}
              isOpen={isDrawerOpen}
              onClose={showDrawer(false)}
            >
              <NavigationPanel isMobile setShowDrawer={setDrawerOpen} />
            </Drawer>
          </div>
        ) : (
          <NavigationPanel />
        )}
        <div className={css.accountInfo} data-testid="account-info">
          <ConditionalComponent flags={[FEATURE.account]}>
            <AccountInfo />
          </ConditionalComponent>
        </div>
      </div>
    </div>
  );
};
