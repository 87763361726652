import { FC, ReactNode, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

import { Card } from '@ebsco-ui/ebsco-ui';

import {
  ItemStatusPanel,
  PlaceHoldModal,
  RemoveHoldModal,
  RenewModal,
  RecordSummaryCard,
  RecordSummaryContext,
} from '@app/components';
import { ItemStatusData, SearchInstanceDto } from '@app/pages/constants';
import { SummaryAvailability, useAppContext } from '@app/contexts';
import { buttonMessages } from '@app/translations';
import { BREAKPOINT } from '@app/constants';

export type SummaryPlacement = 'results' | 'instanceDetails' | 'bookshelf';

export interface RecordSummaryProps {
  instance: SearchInstanceDto;
  placement: SummaryPlacement;
  itemStatusData?: ItemStatusData;
  links?: ReactNode;
  isLast?: boolean;
  availability?: Partial<SummaryAvailability>;
  isAvailabilityLoading?: boolean;
  availabilityLoadingMessage?: string;
  testId?: string;
  altText?: string;
  isRTACShown?: boolean;
}

export const RecordSummary: FC<RecordSummaryProps> = props => {
  const {
    instance,
    placement,
    availability = { isRequestable: true },
    itemStatusData,
    isAvailabilityLoading,
    isLast = false,
    isRTACShown = true,
  } = props;

  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.SCREEN.TABLET_SM - 1}px)`,
  });
  const { $t } = useIntl();
  const [isPlaceHoldModalOpen, setPlaceHoldModalOpen] = useState(false);
  const [isRemoveHoldModalOpen, setRemoveHoldModalOpen] = useState(false);
  const [isRenewHoldModalOpen, setRenewHoldModalOpen] = useState(false);
  const [removeHoldError, setRemoveHoldError] = useState({
    isError: false,
    errorMessage: '',
  });
  const { holdRecordId, setHoldRecordId } = useAppContext();

  const isInstanceDetails = placement === 'instanceDetails';
  const isMobileInstanceDetails = isMobile && isInstanceDetails;
  const isBookshelf = placement === 'bookshelf';
  const isMobileBookshelf = isMobile && isBookshelf;
  const isAvailabilityLoadingForTheFirstTime = Boolean(
    isAvailabilityLoading && !availability.status
  );
  const isCurrentInstanceOnHold = holdRecordId === instance.id;
  const isRenewModal =
    itemStatusData?.actionButtonText === $t(buttonMessages.renew);

  const onModalOpenFromItemStatusPanel = isRenewModal
    ? setRenewHoldModalOpen
    : setRemoveHoldModalOpen;

  const getItemStatusPanel = () => (
    <ItemStatusPanel
      itemStatusData={itemStatusData as ItemStatusData}
      isRemoveHoldError={removeHoldError.isError}
      errorMessage={removeHoldError.errorMessage}
      onModalOpen={onModalOpenFromItemStatusPanel}
      onErrorClose={() =>
        setRemoveHoldError({ isError: false, errorMessage: '' })
      }
    />
  );

  const handleRemoveHoldModalClose = useCallback(() => {
    setRemoveHoldModalOpen(false);
  }, [setRemoveHoldModalOpen]);

  const handleRenewHoldModalClose = useCallback(() => {
    setRenewHoldModalOpen(false);
  }, [setRenewHoldModalOpen]);

  const handlePlaceHoldModalClose = useCallback(() => {
    setPlaceHoldModalOpen(false);
    setHoldRecordId('');
  }, [setPlaceHoldModalOpen, setHoldRecordId]);

  return (
    <RecordSummaryContext.Provider
      value={{
        isInstanceDetails,
        isMobileInstanceDetails,
        isBookshelf,
        isMobileBookshelf,
        isAvailabilityLoadingForTheFirstTime,
        getItemStatusPanel,
        setPlaceHoldModalOpen,
        ...props,
      }}
    >
      <Card
        {...(!isLast && {
          border: 'bottom',
        })}
      >
        <RecordSummaryCard isRTACShown={isRTACShown} />
        <PlaceHoldModal
          isOpen={isPlaceHoldModalOpen || isCurrentInstanceOnHold}
          onModalClose={handlePlaceHoldModalClose}
        />
        {itemStatusData?.itemId && (
          <RenewModal
            isOpen={isRenewHoldModalOpen}
            onModalClose={handleRenewHoldModalClose}
          />
        )}
        {itemStatusData?.holdId && (
          <RemoveHoldModal
            isOpen={isRemoveHoldModalOpen}
            recordId={itemStatusData.holdId}
            onModalClose={handleRemoveHoldModalClose}
            onError={setRemoveHoldError}
          />
        )}
      </Card>
    </RecordSummaryContext.Provider>
  );
};
