import { FC } from 'react';
import cn from 'classnames';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@app/components';
import { useAppContext } from '@app/contexts';
import { UserPersonalInfo } from '@app/hooks';
import { PropsWithClassName } from '@app/constants';

import { getUsersInitials } from './getUsersInitials';

import css from './AccountIcon.module.scss';

export const AccountIcon: FC<PropsWithClassName> = ({ className }) => {
  const { patronSession, isLoggedIn } = useAppContext();

  const getUnauthorizedUser = () => <Icon icon={faUser} className={css.icon} />;
  const getAuthorizedUser = () => (
    <div className={css.username}>
      {getUsersInitials(patronSession?.user as Partial<UserPersonalInfo>)}
    </div>
  );

  return (
    <div className={cn(css.wrapper, className)}>
      {isLoggedIn ? getAuthorizedUser() : getUnauthorizedUser()}
    </div>
  );
};
