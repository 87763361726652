import { IntlShape } from 'react-intl';
import { compact, pick, uniqueId } from 'lodash';

import { getFormattedDate } from '@app/utils';
import { buttonMessages, checkoutMessages } from '@app/translations';

import {
  ItemStatusData,
  LoanDto,
  ChargeDto,
  ITEM_STATUS,
  STATUS_ICON,
} from '../constants';
import { getCheckoutsStatusMessage } from './getCheckoutsStatusesData';

interface ChargesData {
  intl: IntlShape;
  isNonCatalogItem: boolean;
  amount?: number;
  isoCurrencyCode?: string;
}

interface StatusesData {
  itemId: string;
  intl: IntlShape;
  isNonCatalogItem: boolean;
  locale?: string;
  dueDate?: string;
  isOverdue?: boolean;
  instanceId?: string;
}

const getStatusesData = ({
  itemId,
  intl,
  locale,
  isOverdue = false,
  isNonCatalogItem,
  instanceId,
  dueDate,
}: StatusesData): ItemStatusData => {
  const status =
    isOverdue || isNonCatalogItem
      ? ITEM_STATUS.overdue
      : ITEM_STATUS.checkedOut;
  const date = dueDate && locale && getFormattedDate({ date: dueDate, locale });

  return {
    itemId,
    icon: STATUS_ICON[status],
    isNonCatalogItem,
    status,
    ...(!isNonCatalogItem && {
      instanceId,
      statusMessage: getCheckoutsStatusMessage({ date, intl }),
      actionButtonText: intl.$t(buttonMessages.renew),
    }),
  };
};

const getChargesData = ({
  intl,
  isNonCatalogItem,
  amount,
  isoCurrencyCode,
}: ChargesData): Partial<ItemStatusData> => {
  const feesValue =
    amount &&
    isoCurrencyCode &&
    intl.formatNumber(amount, {
      style: 'currency',
      currency: isoCurrencyCode,
    });

  return {
    feesTitle: intl.$t(
      isNonCatalogItem ? checkoutMessages.amount : checkoutMessages.fees
    ),
    feesValue: feesValue || '',
  };
};

export const getFeesStatusesData = (
  charges: ChargeDto[],
  loans: LoanDto[],
  intl: IntlShape,
  locale: string
): ItemStatusData[] =>
  compact(
    charges.map(
      ({
        item: chargeItem,
        chargeAmount: { amount, isoCurrencyCode },
        reason,
      }) => {
        const currentLoan = loans.find(
          loan => loan.item?.itemId === chargeItem?.itemId
        );

        return Object.assign(
          getStatusesData(
            currentLoan
              ? {
                  ...pick(currentLoan.item, 'itemId', 'instanceId'),
                  isOverdue: currentLoan.overdue,
                  dueDate: currentLoan.dueDate,
                  isNonCatalogItem: !chargeItem,
                  intl,
                  locale,
                }
              : {
                  itemId: uniqueId(reason),
                  isNonCatalogItem: !chargeItem,
                  intl,
                }
          ),
          getChargesData({
            intl,
            isNonCatalogItem: !chargeItem,
            amount,
            isoCurrencyCode,
          })
        );
      }
    )
  );
