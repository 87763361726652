import { ALL_RECORDS_CQL, searchOptionMap, SEARCH_OPTION } from '@app/search';

export const getQueryCQL = (query: string, option: string): string => {
  const escapedQuery = query?.replace(/[\\"]/g, '\\$&');

  if (
    searchOptionMap[option] === searchOptionMap[SEARCH_OPTION.keyword] &&
    (!query || query === '*')
  ) {
    return ALL_RECORDS_CQL;
  }

  if (query && option === SEARCH_OPTION.advanced) {
    return query;
  }

  return query && `${searchOptionMap[option]} "${escapedQuery}"`;
};
