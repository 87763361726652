import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';

import {
  Button,
  Loading,
  Modal,
  createBemBlockBuilder,
} from '@ebsco-ui/ebsco-ui';

import {
  RenewNotification,
  ModalNotificationType,
  ModalHeader,
  getErrorReason,
  useRecordSummaryContext,
} from '@app/components';
import { getCheckoutsStatusMessage } from '@app/pages/utils';
import { useAppContext } from '@app/contexts';
import { useRenewMutation } from '@app/hooks';
import { getFormattedDate } from '@app/utils';
import { sharedMessages, buttonMessages } from '@app/translations';
import {
  IS_TEST_ENV,
  MODAL_PLACEMENT,
  NOTIFICATION_STATUS,
} from '@app/constants';

import '../Modal/Modal.scss';

interface RenewModalProps {
  isOpen: boolean;
  onModalClose: () => void;
}

const cnBem = createBemBlockBuilder(['modal']);

export const RenewModal: FC<RenewModalProps> = ({ isOpen, onModalClose }) => {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState<ModalNotificationType | null>(null);
  const [statusMessage, setStatusMessage] = useState('');
  const intl = useIntl();
  const { $t } = intl;
  const { locale } = useAppContext();
  const renewMutation = useRenewMutation();
  const { instance, itemStatusData } = useRecordSummaryContext();

  useEffect(() => {
    const queryStatus = renewMutation.status;

    if (
      queryStatus === NOTIFICATION_STATUS.error ||
      queryStatus === NOTIFICATION_STATUS.success
    ) {
      setStatus(queryStatus);
    }

    if (queryStatus === NOTIFICATION_STATUS.success) {
      const date = getFormattedDate({
        date: renewMutation.data.dueDate,
        locale,
      });

      setStatusMessage(getCheckoutsStatusMessage({ date, intl }));
    }
  }, [instance.id, locale, renewMutation.data, renewMutation.status, intl]);

  const handleCloseButtonClick = () => {
    if (status === NOTIFICATION_STATUS.success) {
      queryClient.refetchQueries(['bookshelfPage'], { type: 'active' });
      queryClient.refetchQueries(['userItemStatuses'], { type: 'active' });
    }

    onModalClose();
  };

  const getModalFooter = () => {
    return (
      <>
        <Button
          data-testid="modal-footer-close"
          styleType="text"
          onClick={handleCloseButtonClick}
        >
          {$t(status ? sharedMessages.close : sharedMessages.cancel)}
        </Button>
        {!status && (
          <Button
            title={$t(buttonMessages.renew)}
            styleType="default"
            onClick={() => {
              renewMutation.mutate({
                itemId: itemStatusData?.itemId as string,
              });
            }}
          >
            {$t(buttonMessages.renew)}
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      disableAnimations={IS_TEST_ENV}
      isOpen={isOpen}
      modalTitle={$t({
        id: 'renewModal.renewCheckout',
        defaultMessage: 'Renew checkout',
      })}
      appElement={MODAL_PLACEMENT}
      className={cnBem()}
      onClose={onModalClose}
      onAfterClose={() => setStatus(null)}
    >
      <div
        data-testid="renew-modal"
        className={cn({
          [cnBem('__disabledScroll')]: renewMutation.isLoading,
        })}
      >
        <ModalHeader
          headerTitle={$t({
            id: 'renewModal.renewCheckout',
            defaultMessage: 'Renew checkout',
          })}
          onModalClose={onModalClose}
        />
        {status && (
          <Modal.Body>
            <RenewNotification
              type={status}
              statusMessage={statusMessage}
              errorMessage={getErrorReason(renewMutation)}
            />
          </Modal.Body>
        )}
        <Modal.Footer>{getModalFooter()}</Modal.Footer>
        {renewMutation.isLoading && (
          <Loading
            size="large"
            loadingMessage={$t({
              id: 'renewModal.renewingCheckout',
              defaultMessage: 'Renewing checkout...',
            })}
          />
        )}
      </div>
    </Modal>
  );
};
