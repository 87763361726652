import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

// global styles should be included before other imports, so they come before
// component styles in bundle and thus can be overridden in them without using !import
import './style.scss';

import { App } from './App';
import { createReactQueryClient } from './components';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <Router>
      <QueryClientProvider client={createReactQueryClient()}>
        <App />
      </QueryClientProvider>
    </Router>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
