import { FormattedMessage } from 'react-intl';

import { sharedMessages } from '@app/translations';

export const barcodePinFieldsDescription = [
  {
    label: <FormattedMessage {...sharedMessages.barcode} />,
    name: 'barcode',
  },
  {
    label: <FormattedMessage {...sharedMessages.pin} />,
    name: 'pin',
    isPasswordField: true,
  },
];

export const barcodeNameFieldsDescription = [
  {
    label: <FormattedMessage {...sharedMessages.barcode} />,
    name: 'barcode',
  },
  {
    label: <FormattedMessage {...sharedMessages.name} />,
    name: 'pin',
    isPasswordField: true,
  },
];

export const usernamePasswordFieldsDescription = [
  {
    label: <FormattedMessage id="login.username" defaultMessage="Username" />,
    name: 'username',
  },
  {
    label: <FormattedMessage id="login.password" defaultMessage="Password" />,
    name: 'password',
    isPasswordField: true,
  },
];
