import { UseQueryResult } from '@tanstack/react-query';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarCheck,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarExclamation } from '@fortawesome/pro-regular-svg-icons';

import { holdMessages } from '@app/translations';

export interface ContributorDto {
  name: string;
}

export interface InstanceItemPublication {
  dateOfPublication: string;
  publisher?: string;
  place?: string;
  role?: string;
}

export interface InstanceItem {
  instanceId: string;
  itemId: string;
  title: string;
  authors?: string[];
  isbns?: string[];
  sourceTypes?: string[];
  publication?: InstanceItemPublication[];
}

export interface HoldDto {
  item: InstanceItem;
  pickupLocationId: string;
  queuePosition: number;
  requestDate: string;
  requestId: string;
  status: string;
}

export interface LoanDto {
  dueDate: string;
  id: string;
  item: InstanceItem;
  loanDate: string;
  overdue: boolean;
}

export interface ChargeDto {
  accrualDate: string;
  state: string;
  reason: string;
  chargeAmount: {
    amount: number;
    isoCurrencyCode: string;
  };
  item?: InstanceItem;
}

export interface ItemStatusDto {
  totalHolds: number;
  totalLoans: number;
  totalChargesCount: number;
  totalCharges: {
    amount: number;
    isoCurrencyCode: string;
  };
  holds?: HoldDto[];
  loans?: LoanDto[];
  charges?: ChargeDto[];
}

export interface ItemStatusData {
  itemId: string;
  icon: IconDefinition;
  status: string;
  isNonCatalogItem?: boolean;
  instanceId?: string;
  actionButtonText?: string;
  statusMessage?: string;
  feesTitle?: string;
  feesValue?: string;
  holdId?: string;
}

export interface DepartmentInstructorItemDto {
  id: string;
  name: string;
}

export enum ITEM_STATUS {
  onHold = 'On hold',
  checkedOut = 'Checked out',
  overdue = 'Overdue',
}

export const HOLD_STATUS_MESSAGE_MAP = {
  'Open - Not yet filled': holdMessages.queue,
  'Open - Awaiting pickup': holdMessages.readyForPickup,
  'Open - In transit': holdMessages.queue,
  'Open - Awaiting delivery': holdMessages.queue,
};

export const STATUS_ICON = {
  [ITEM_STATUS.onHold]: faCalendarAlt,
  [ITEM_STATUS.checkedOut]: faCalendarCheck,
  [ITEM_STATUS.overdue]: faCalendarExclamation,
};

export interface Resource<PageType, QueryResultType> {
  pages: PageType[][];
  totalRecords: number;
  queryResult: UseQueryResult<QueryResultType>;
  next?: string;
}

export * from './SearchResultsPage/searchResultsTypes';
export * from './BookshelfPage/bookshelfTypes';
export * from './CourseReservesPage/courseReservesTypes';
export * from './CallNumbersPage/callNumbersTypes';
