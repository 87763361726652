import { IntlShape } from 'react-intl';

import { buttonMessages } from '@app/translations';

import {
  ItemStatusData,
  HoldDto,
  ITEM_STATUS,
  STATUS_ICON,
  HOLD_STATUS_MESSAGE_MAP,
} from '../constants';

export const getHoldsStatusesData = (
  holdsData: HoldDto[],
  intl: IntlShape
): ItemStatusData[] =>
  holdsData.map(({ item, status, queuePosition, requestId }) => ({
    holdId: requestId,
    instanceId: item.instanceId,
    itemId: item.itemId,
    icon: STATUS_ICON[ITEM_STATUS.onHold],
    status: ITEM_STATUS.onHold,
    statusMessage: intl.$t(HOLD_STATUS_MESSAGE_MAP[status], {
      value: queuePosition,
    }),
    actionButtonText: intl.$t(buttonMessages.removeHold),
  }));
