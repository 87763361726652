import { IntlShape } from 'react-intl';

import { getFormattedDate } from '@app/utils';
import {
  buttonMessages,
  checkoutMessages,
  sharedMessages,
} from '@app/translations';

import {
  ItemStatusData,
  LoanDto,
  ChargeDto,
  ITEM_STATUS,
  STATUS_ICON,
} from '../constants';

interface StatusesData {
  date: string;
  itemId: string;
  instanceId: string;
  isOverdue: boolean;
  intl: IntlShape;
}

const getStatusesData = ({
  date,
  itemId,
  instanceId,
  isOverdue,
  intl,
}: StatusesData): ItemStatusData => {
  const status = isOverdue ? ITEM_STATUS.overdue : ITEM_STATUS.checkedOut;

  return {
    itemId,
    instanceId,
    icon: STATUS_ICON[status],
    status,
    statusMessage: getCheckoutsStatusMessage({ date, intl }),
    actionButtonText: intl.$t(buttonMessages.renew),
  };
};

const getChargesData = (
  id: string,
  charges: ChargeDto[],
  intl: IntlShape
): Partial<ItemStatusData> => {
  const currentCharges = charges.find(charge => charge.item?.itemId === id);
  const amount = currentCharges?.chargeAmount?.amount;
  const isoCurrencyCode = currentCharges?.chargeAmount?.isoCurrencyCode;
  const feesValue =
    amount &&
    isoCurrencyCode &&
    intl.formatNumber(amount, {
      style: 'currency',
      currency: isoCurrencyCode,
    });

  return {
    feesTitle: intl.$t(checkoutMessages.fees),
    feesValue: feesValue || '',
  };
};

export const getCheckoutsStatusMessage = ({
  date,
  intl,
}: {
  intl: IntlShape;
  date?: string;
}): string => intl.$t(sharedMessages.dueDate, { date });

export const getCheckoutsStatusesData = (
  loans: LoanDto[],
  charges: ChargeDto[],
  intl: IntlShape,
  locale: string
): ItemStatusData[] =>
  loans.map(({ item: { instanceId, itemId }, overdue, dueDate }) => {
    const date = getFormattedDate({ date: dueDate, locale });

    return Object.assign(
      getStatusesData({ date, itemId, instanceId, isOverdue: overdue, intl }),
      getChargesData(itemId, charges, intl)
    );
  });
