import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { authenticateToken, PatronSession } from '@app/hooks';

export interface AppContextProps {
  locale: string;
  patronSession: PatronSession | null;
  isLoggedIn: boolean;
  holdRecordId: string;
  authenticateToken: authenticateToken;
  setHoldRecordId: Dispatch<SetStateAction<string>>;
  setPatronSession: Dispatch<SetStateAction<PatronSession | null>>;
  setSelectedLocale: Dispatch<SetStateAction<string>>;
}

export const AppContext = createContext<AppContextProps>(null!);

export const useAppContext = (): AppContextProps => {
  return useContext(AppContext);
};
