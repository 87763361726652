import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
} from 'react';

import { RecordSummaryProps } from '@app/components';

export interface RecordSummaryContextProps extends RecordSummaryProps {
  isInstanceDetails: boolean;
  isMobileInstanceDetails: boolean;
  isBookshelf: boolean;
  isMobileBookshelf: boolean;
  isAvailabilityLoadingForTheFirstTime: boolean;
  getItemStatusPanel: () => ReactElement;
  setPlaceHoldModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const RecordSummaryContext = createContext<RecordSummaryContextProps>(
  null!
);

export const useRecordSummaryContext = (): RecordSummaryContextProps =>
  useContext(RecordSummaryContext);
