import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import {
  emitScrollTo,
  IndeterminateSpinner,
  Page,
  PageErrorMessage,
  ShowMorePagination,
} from '@ebsco-ui/ebsco-ui';

import {
  ExternalLink,
  FilterPanel,
  Icon,
  RecordLinks,
  RecordSummary,
  SortByDropdown,
} from '@app/components';
import { SearchInstanceDto } from '@app/pages';
import { searchResultSortItems } from '@app/search';
import {
  useAppContext,
  useAvailabilityContext,
  useSearchContext,
  useMappingsContext,
} from '@app/contexts';
import { useFeatureFlag, usePaginationItemFocus } from '@app/hooks';
import { setFocusOn } from '@app/utils';
import { getPageMessages, sharedMessages } from '@app/translations';
import {
  ADVANCED_SEARCH_ERROR,
  FEATURE,
  RECORDS_PER_PAGE,
} from '@app/constants';

import { getItemStatusData, getItemWithStatus } from '../utils';

import css from './SearchResultsPage.module.scss';

export const SearchResultsPage: FC = () => {
  const intl = useIntl();
  const { $t } = intl;
  const { locale } = useAppContext();
  const { userItemStatuses } = useMappingsContext();
  const RTACForSearchFlag = useFeatureFlag(FEATURE.RTACForSearch);
  const passthroughLinkFlag = useFeatureFlag(FEATURE.passthroughlink);
  const {
    state: { query, sortField, sortDirection, pageNumber },
    searchOption,
    shouldRefocusRef,
    searchResults: {
      searchResultsQuery: {
        isFetching,
        isRefetching,
        isError,
        error,
        fetchStatus,
        status,
        refetch,
      },
      pages,
      currentPage,
      totalRecords,
    },
    updateState,
    instanceIdScrollTo,
    isPageFromBackButton,
    setInstanceIdScrollTo,
    setIsPageFromBackButton,
  } = useSearchContext();

  const [isShowMoreLoading, setShowMoreLoading] = useState(false);
  const { getAvailability, availability, isAvailabilityLoading } =
    useAvailabilityContext();
  const mainContainerRef = useRef<HTMLDivElement>(null!);
  const { search: urlQuery } = useLocation();
  const isSearchPerformed = fetchStatus !== 'fetching' && status !== 'loading';
  const isSearchSuccessful =
    !isRefetching && status === 'success' && !isEmpty(pages[0]);

  const getRecordsIds = (records: SearchInstanceDto[] = []) =>
    records.map(record => record.id);

  usePaginationItemFocus({
    pageNumber,
    totalPagesNumber: pages.length,
    instanceIdScrollTo,
  });

  useEffect(() => {
    if (isSearchSuccessful) {
      getAvailability(getRecordsIds(currentPage), pageNumber);
    }
  }, [getAvailability, isSearchSuccessful, currentPage, pageNumber]);

  useEffect(() => {
    if (!isFetching) {
      setShowMoreLoading(false);
    }
  }, [setShowMoreLoading, isFetching]);

  const handleSortBySelect = useCallback(
    option => {
      updateState({
        sortField: option.sortField,
        sortDirection: option.sortDirection,
        option: searchOption,
      });
      shouldRefocusRef.current = true;
    },
    [updateState, searchOption, shouldRefocusRef]
  );

  const renderPageStatus = () => {
    if (isFetching) {
      return (
        <div className={css.messageWrapper} data-testid="page-spinner">
          <IndeterminateSpinner level="component" />
        </div>
      );
    }

    if (isError) {
      if (
        error &&
        error.errors?.[0].code === ADVANCED_SEARCH_ERROR.CODE &&
        error.errors?.[0].type === ADVANCED_SEARCH_ERROR.TYPE
      ) {
        return (
          <PageErrorMessage
            title={$t({
              id: 'resultsList.searchInvalidTitle',
              defaultMessage: 'Invalid search',
            })}
            text={$t(sharedMessages.searchInvalidText)}
            titleTag="h1"
          />
        );
      }

      return (
        <PageErrorMessage
          buttonText={$t(sharedMessages.refreshPage)}
          title={$t({
            id: 'resultsList.searchUnavailableTitle',
            defaultMessage: 'Search unavailable',
          })}
          text={$t(sharedMessages.searchUnavailableText)}
          titleTag="h1"
          onClick={() => refetch()}
        />
      );
    }

    const areResultsEmpty = isSearchPerformed && totalRecords === 0;

    if (areResultsEmpty) {
      return (
        <div data-testid="no-search-results" className={css.noResults}>
          <FormattedMessage
            {...sharedMessages.results}
            values={{ number: 0 }}
            tagName="p"
          />
          <FormattedMessage
            id="resultsList.noResults"
            defaultMessage="No results found"
            tagName="p"
          />
        </div>
      );
    }

    if (!isSearchPerformed) {
      return (
        <h1 className={css.messageWrapper}>
          <FormattedMessage
            id="resultsList.enterQuery"
            defaultMessage="Choose a filter or enter a search query to show results."
          />
        </h1>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!isFetching && shouldRefocusRef.current) {
      mainContainerRef.current.focus({ preventScroll: true });
      shouldRefocusRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (
      !isFetching &&
      urlQuery &&
      (status === 'success' || status === 'error')
    ) {
      mainContainerRef.current.focus({ preventScroll: true });
      emitScrollTo(0, undefined);
    }

    if (isFetching) {
      shouldRefocusRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (instanceIdScrollTo && !isEmpty(pages)) {
      const selector = `[data-link="${instanceIdScrollTo}-link"]`;

      setFocusOn({ selector });
      setInstanceIdScrollTo(null);
    } else if (!instanceIdScrollTo && isPageFromBackButton) {
      const element = document.querySelector('[data-link]') as HTMLElement;

      setFocusOn({ element });
      setIsPageFromBackButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  return (
    <>
      <Helmet>
        <title>
          {$t(
            {
              id: 'searchResults.documentTitle',
              defaultMessage:
                '{searchTerm, select, null {Search results - EBSCO Locate} other {{searchTerm} - Search results - EBSCO Locate}}',
            },
            { searchTerm: query || null }
          )}
        </title>
      </Helmet>
      <div
        className={cn(css.container, 'container')}
        data-testid="search-page-wrapper"
      >
        <FilterPanel />
        <main
          className={css.wrapper}
          data-testid="search-results-wrapper"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          ref={mainContainerRef}
          data-focus="focusedNode"
        >
          {pageNumber === 1 && renderPageStatus()}
          {(isSearchSuccessful || pageNumber > 1) && (
            <>
              <div className={css.resultsPanel}>
                <h1
                  className={css.resultsCount}
                  data-testid="searchResultsTotalRecords"
                >
                  <FormattedMessage
                    {...sharedMessages.results}
                    values={{
                      number: (
                        <FormattedNumber value={totalRecords as number} />
                      ),
                    }}
                  />
                </h1>
                <SortByDropdown
                  options={searchResultSortItems}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  onSelect={handleSortBySelect}
                />
              </div>
              {passthroughLinkFlag?.isActive && (
                <div className={css.passthrough} data-testid="passthrough-link">
                  {$t(
                    {
                      id: 'searchResults.searchForMobius',
                      defaultMessage:
                        'Search for <i>"{term}"</i> across <a>MOBIUS</a>',
                    },
                    {
                      term: query,
                      i: chunks => <i>{chunks}</i>,
                      a: chunks => (
                        <>
                          <ExternalLink
                            href={`${passthroughLinkFlag?.config?.consortia}search?query=${query}`}
                          >
                            {chunks}
                          </ExternalLink>
                          <Icon icon={faExternalLink} />
                        </>
                      ),
                    }
                  )}
                </div>
              )}
              <ShowMorePagination
                uniqueName="searchResults"
                error={isError}
                messages={getPageMessages(intl)}
                isLoading={isShowMoreLoading}
                totalRecords={totalRecords as number}
                recordsPerPage={RECORDS_PER_PAGE}
                pages={pages}
                onLoadPage={() => {
                  setShowMoreLoading(true);

                  return isError
                    ? refetch()
                    : updateState({ pageNumber: pageNumber + 1 });
                }}
              >
                {(records, currentPageNumber) => (
                  <Page
                    title={$t(sharedMessages.pageNumber, {
                      pageNumber: currentPageNumber,
                    })}
                    key={currentPageNumber}
                    number={currentPageNumber}
                    uniqueName="searchResultsDivider"
                  >
                    {records.map((instance, index) => {
                      const itemWithStatus = getItemWithStatus(
                        instance.id,
                        userItemStatuses
                      );

                      return (
                        <RecordSummary
                          testId="record-summary"
                          key={instance.id}
                          instance={instance}
                          placement="results"
                          itemStatusData={
                            itemWithStatus &&
                            getItemStatusData(itemWithStatus, intl, locale)
                          }
                          availability={
                            availability[instance.id]?.summaryAvailability
                          }
                          isAvailabilityLoading={
                            currentPageNumber === pages.length - 1 &&
                            isAvailabilityLoading
                          }
                          isRTACShown={RTACForSearchFlag?.isActive}
                          links={
                            <RecordLinks
                              id={instance.id}
                              title={instance.title}
                              onClick={() => {
                                setInstanceIdScrollTo(instance.id);
                              }}
                            />
                          }
                          isLast={index === records.length - 1}
                        />
                      );
                    })}
                  </Page>
                )}
              </ShowMorePagination>
            </>
          )}
        </main>
      </div>
    </>
  );
};
