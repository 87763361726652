import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
} from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { SelectedFacetAttributes } from '@app/components';
import {
  SearchInstanceDto,
  SearchResultsDto,
  SearchResultsErrorsDto,
} from '@app/pages/constants';
import {
  PublicationYear,
  SEARCH_OPTION,
  SearchState,
  SearchStateInitialized,
} from '@app/search';

export const SearchContext = createContext<SearchContextProps>(null!);

interface Results {
  searchResultsQuery: UseQueryResult<SearchResultsDto, SearchResultsErrorsDto>;
  pages: Array<SearchInstanceDto[]>;
  currentPage?: SearchInstanceDto[];
  totalRecords?: number;
}

export interface SearchContextProps {
  state: SearchStateInitialized;
  searchOption: SEARCH_OPTION;
  searchResults: Results;
  selectedFacets: SelectedFacetAttributes;
  selectedPublicationYear: PublicationYear;
  shouldRefocusRef: MutableRefObject<boolean>;
  instanceIdScrollTo: string | null;
  isPageFromBackButton: boolean;
  updateState: Dispatch<SetStateAction<Partial<SearchState>>>;
  setSelectedFacets: Dispatch<SetStateAction<SelectedFacetAttributes>>;
  setSelectedPublicationYear: Dispatch<SetStateAction<PublicationYear>>;
  setSearchOption: Dispatch<SetStateAction<string>>;
  setInstanceIdScrollTo: Dispatch<SetStateAction<string | null>>;
  setIsPageFromBackButton: Dispatch<SetStateAction<boolean>>;
}

export const useSearchContext = (): SearchContextProps =>
  useContext(SearchContext);
