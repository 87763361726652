import { FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';
import { isEmpty } from 'lodash';

import { ScrollContainer } from '@ebsco-ui/ebsco-ui';
import { FeatureFlagsProvider, MainContentContainer } from '@app/components';
import { SearchProvider, MappingsProvider } from '@app/search';
import {
  AppContext,
  AppContextProps,
  SearchContextProps,
  FeatureFlag,
} from '@app/contexts';
import { ENGLISH_LOCALE_KEY } from '@app/constants';

import { AvailabilityProvider } from '../AvailabilityProvider';

interface ProviderRootProps {
  appContext: AppContextProps;
  messages: Record<string, string>;
  searchContext?: Partial<SearchContextProps>;
  featureFlags?: FeatureFlag[];
}

export const ProviderRoot: FC<ProviderRootProps> = ({
  appContext,
  messages,
  searchContext,
  featureFlags,
  children,
}) => {
  const [appLocale, setAppLocale] = useState(ENGLISH_LOCALE_KEY);
  const { locale } = appContext;

  useEffect(() => {
    if (locale === ENGLISH_LOCALE_KEY || !isEmpty(messages)) {
      setAppLocale(locale);
    }
  }, [locale, messages]);

  return (
    <AppContext.Provider value={appContext}>
      <IntlProvider locale={appLocale} messages={messages}>
        <HelmetProvider>
          <FeatureFlagsProvider flags={featureFlags}>
            <SearchProvider searchContext={searchContext}>
              <MappingsProvider>
                <AvailabilityProvider>
                  <ScrollContainer
                    renderContainer={({ ...rest }, ref) => (
                      <MainContentContainer scrollRef={ref} {...rest} />
                    )}
                  >
                    {children}
                  </ScrollContainer>
                </AvailabilityProvider>
              </MappingsProvider>
            </SearchProvider>
          </FeatureFlagsProvider>
        </HelmetProvider>
      </IntlProvider>
    </AppContext.Provider>
  );
};
