import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { AuthForm, AuthPageWrapper } from '@app/components';
import { usernamePasswordFieldsDescription } from '@app/pages';
import { useAuthentication } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { LOGIN_METHOD } from '@app/constants';

export const DirectLoginPage: FC = () => {
  const { $t } = useIntl();
  const { notification, isLoading, loginUser } = useAuthentication({
    method: LOGIN_METHOD.direct,
    passwordFieldName: 'password',
  });

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'login.documentTitle',
            defaultMessage: 'Login - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <AuthPageWrapper
        title={$t(sharedMessages.welcomeBack)}
        notification={notification}
      >
        <AuthForm
          fields={usernamePasswordFieldsDescription}
          isLoading={isLoading}
          onSubmit={loginUser}
        />
      </AuthPageWrapper>
    </>
  );
};
