import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { flatten } from 'lodash';

import {
  CallNumbersItemDto,
  CallNumbersDto,
  Resource,
} from '@app/pages/constants';
import { axios, escapeForbiddenSymbolsInQuery } from '@app/utils';
import { RECORDS_PER_PAGE } from '@app/constants';

import { usePaging, useQueryParams } from '..';

export const useCallNumbersFetch = (
  pageNumber: number,
  searchQuery: string
): Resource<CallNumbersItemDto, CallNumbersDto> => {
  const [pages, setPages] = useState<CallNumbersItemDto[][]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [fetchedData, setFetchedData] = useState<CallNumbersItemDto[]>([]);
  const queryParams = useQueryParams();

  const callNumbersQuery = useQuery(
    ['callNumbersPage', searchQuery, pageNumber],
    () => {
      const escapedQuery = escapeForbiddenSymbolsInQuery(searchQuery);
      const isSearchedQuery = searchQuery === queryParams.search;
      const highlightQuery = `callNumber>="${escapedQuery}" or callNumber<"${escapedQuery}"`;
      const paginationQuery = `callNumber>"${escapedQuery}"`;

      return axios
        .get<CallNumbersDto>('/browse/call-numbers/instances', {
          params: {
            highlightMatch: true,
            limit: RECORDS_PER_PAGE,
            precedingRecordsCount: 4,
            query: isSearchedQuery ? highlightQuery : paginationQuery,
          },
        })
        .then(response => response.data);
    },
    {
      enabled: Boolean(searchQuery),
    }
  );

  useEffect(() => {
    if (callNumbersQuery.data?.totalRecords !== undefined) {
      const hasNextPage = callNumbersQuery.data?.next;

      if (hasNextPage) {
        setTotalRecords(callNumbersQuery.data.totalRecords);
      } else {
        setTotalRecords(flatten(pages).length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  useEffect(() => {
    if (callNumbersQuery.isSuccess && callNumbersQuery.data?.items) {
      setFetchedData(
        callNumbersQuery.data.items.map(record => ({
          ...record,
          // TODO: change "record.shelfKey" to "record.instance.id" when the issue with collision will be fixed by Falcon team
          id: record.shelfKey,
        }))
      );
    }
  }, [callNumbersQuery.data?.items, callNumbersQuery.isSuccess, pageNumber]);

  usePaging<CallNumbersItemDto>({
    areRecordsFetched: callNumbersQuery.isSuccess,
    records: fetchedData,
    pageNumber,
    setPages,
  });

  return {
    pages,
    totalRecords,
    queryResult: callNumbersQuery,
    next: callNumbersQuery.data?.next,
  };
};
