import { FC, LegacyRef, ReactElement, RefObject } from 'react';
import cn from 'classnames';

import { PageBanner } from '@app/components';
import { PropsWithClassName } from '@app/constants';

import css from './MainContentContainer.module.scss';

interface MainContentContainerProps {
  scrollRef: LegacyRef<ReactElement>;
}

export const MainContentContainer: FC<
  PropsWithClassName<MainContentContainerProps>
> = ({ scrollRef, className, ...rest }) => {
  const ref = scrollRef as unknown as RefObject<HTMLDivElement>;
  const hasBanner = Boolean(process.env.REACT_APP_PAGE_BANNER_MESSAGE);

  return (
    <div className={css.wrapper}>
      {hasBanner && (
        <PageBanner
          message={process.env.REACT_APP_PAGE_BANNER_MESSAGE as string}
        />
      )}
      <div
        className={cn(css.mainContainer, className, {
          [css.hasBanner]: hasBanner,
        })}
        tabIndex={-1}
        ref={ref}
        id="mainContainer"
        {...rest}
      />
    </div>
  );
};
