import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';

import { Popover } from '@ebsco-ui/ebsco-ui';

import { AccountIcon, Icon } from '@app/components';
import { useAppContext } from '@app/contexts';
import { sharedMessages } from '@app/translations';
import { ROUTE } from '@app/constants';

import css from './AccountInfo.module.scss';

export const AccountInfo: FC = () => {
  const { $t } = useIntl();
  const { patronSession, isLoggedIn } = useAppContext();

  return (
    <Popover
      uniqueName="account-info"
      panelPlacement="bottom-end"
      className={css.wrapper}
    >
      <Popover.ContextConsumer>
        {({ closePopover }) => (
          <>
            <Popover.Button
              styleType="flat-icon"
              id="account-info-button"
              className={cn(css.userButton, 'popoverButton')}
              aria-label={$t({
                id: 'navigation.openUserInfo',
                defaultMessage: 'Open user info',
              })}
            >
              <AccountIcon />
            </Popover.Button>
            <Popover.Panel
              aria-describedby="account-info-button"
              className="popoverWrapper"
            >
              <h2 className={css.header}>
                {isLoggedIn ? (
                  <FormattedMessage
                    id="navigation.myAccount"
                    defaultMessage="My account"
                  />
                ) : (
                  <FormattedMessage
                    id="navigation.libraryUser"
                    defaultMessage="Library user"
                  />
                )}
              </h2>
              <div className={css.userInfoWrapper}>
                <AccountIcon className={css.userIcon} />
                {patronSession && isLoggedIn && (
                  <div className={css.userInfo}>
                    <div className={css.username} data-testid="user-name">
                      {patronSession.user?.firstName}{' '}
                      {patronSession.user?.lastName}
                    </div>
                    <div className={css.userEmail} data-testid="user-email">
                      {patronSession.user?.email}
                    </div>
                  </div>
                )}
              </div>
              <ul className={css.links}>
                <li>
                  <Icon icon={faUserEdit} className={css.linkIcon}>
                    <FormattedMessage {...sharedMessages.preferences}>
                      {message => (
                        <Link to={ROUTE.preferences} onClick={closePopover}>
                          {message[0]}
                        </Link>
                      )}
                    </FormattedMessage>
                  </Icon>
                </li>
                <li>
                  <Icon icon={faSignOut} className={css.linkIcon}>
                    {isLoggedIn ? (
                      <FormattedMessage
                        id="navigation.signOut"
                        defaultMessage="Sign out"
                      >
                        {message => <Link to={ROUTE.logout}>{message[0]}</Link>}
                      </FormattedMessage>
                    ) : (
                      <FormattedMessage {...sharedMessages.signIn}>
                        {message => <Link to={ROUTE.login}>{message[0]}</Link>}
                      </FormattedMessage>
                    )}
                  </Icon>
                </li>
              </ul>
            </Popover.Panel>
          </>
        )}
      </Popover.ContextConsumer>
    </Popover>
  );
};
