import { Dispatch, FC, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import {
  SearchMultiselectDropdown,
  createBemBlockBuilder,
} from '@ebsco-ui/ebsco-ui';

import { InstitutionItem } from '@app/pages';
import { ArrayElement } from '@app/utils';
import { sharedMessages } from '@app/translations';

import './AuthMethodSwitch.scss';

type MultiselectProps = Omit<
  Parameters<typeof SearchMultiselectDropdown>[0],
  'children'
>;

export type MultiselectDropdownItem = ArrayElement<MultiselectProps['options']>;

interface AuthMethodSwitchProps {
  options: MultiselectDropdownItem[];
  selectedItem: MultiselectDropdownItem;
  setCurrentMethod: Dispatch<SetStateAction<InstitutionItem>>;
}

const cnBem = createBemBlockBuilder(['authMethodSwitch']);
const INSTITUTION_LENGTH_TO_DISPLAY_SEARCH = 10;

export const AuthMethodSwitch: FC<AuthMethodSwitchProps> = ({
  options,
  selectedItem,
  setCurrentMethod,
}) => {
  const { $t } = useIntl();

  return (
    <SearchMultiselectDropdown
      options={options}
      initialSelectedOptions={[selectedItem]}
      isMultiSelect={false}
      isSearchable={options.length >= INSTITUTION_LENGTH_TO_DISPLAY_SEARCH}
      showSearchButton
      className={cnBem()}
      messages={{
        dropdownLabelText: $t({
          id: 'login.letsFindYourInstitution',
          defaultMessage: "Let's find your institution",
        }),
        inputBoxPlaceholder: $t({
          id: 'login.findYourInstitution',
          defaultMessage: 'Find your institution',
        }),
        toggleBtnText: $t({
          id: 'login.openInstitutionsList',
          defaultMessage: 'Open institutions list',
        }),
        clearBtnAriaText: $t(sharedMessages.clear),
        searchBtnAriaText: $t(sharedMessages.search),
        comboboxAriaText: $t({
          id: 'login.selectOptions',
          defaultMessage: 'Select options',
        }),
        AllText: '',
        nItemSelectedText: '',
        loadingText: '',
        selectAllText: '',
        chipsDltBtnAriaText: '',
        clearAllBtnText: '',
      }}
      onSelectedItemChangeCb={setCurrentMethod}
    />
  );
};
