import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { Button } from '@ebsco-ui/ebsco-ui';

import {
  getErrorNotificationProps,
  Icon,
  ModalNotification,
} from '@app/components';
import { ITEM_STATUS, ItemStatusData } from '@app/pages/constants';
import { useFeatureFlag } from '@app/hooks';
import { statusMessages } from '@app/translations';
import { FEATURE, ROUTE } from '@app/constants';

import css from './ItemStatusPanel.module.scss';

interface ItemStatusDataProps {
  itemStatusData: ItemStatusData;
  errorMessage?: string;
  isRemoveHoldError?: boolean;
  onModalOpen: Dispatch<SetStateAction<boolean>>;
  onErrorClose: () => void;
}

export const ItemStatusPanel: FC<ItemStatusDataProps> = ({
  itemStatusData: {
    icon,
    isNonCatalogItem,
    status,
    statusMessage,
    actionButtonText,
    feesTitle,
    feesValue,
  },
  errorMessage = '',
  isRemoveHoldError,
  onModalOpen,
  onErrorClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const bookshelfFlag = useFeatureFlag(FEATURE.bookshelf);
  const { $t } = useIntl();

  const link = status === ITEM_STATUS.onHold ? ROUTE.holds : ROUTE.checkouts;
  const isBookshelfRoute = [ROUTE.holds, ROUTE.checkouts, ROUTE.fees].includes(
    location.pathname as ROUTE
  );

  const handleButtonClick = useCallback(() => {
    if (isBookshelfRoute) {
      onModalOpen(true);
    } else {
      navigate(link);
    }
  }, [navigate, link, isBookshelfRoute, onModalOpen]);

  return (
    <div className={css.wrapper} data-testid="status-panel">
      <div className={css.statusWrapper}>
        <Icon icon={icon} className={cn(css.icon, css[icon.iconName])}>
          <div className={css.statusInfo}>
            <span>
              {isNonCatalogItem ? (
                <strong data-testid="status-title">
                  {$t(statusMessages.feeDue)}
                </strong>
              ) : (
                <>
                  <strong data-testid="status-title">
                    {`${$t(statusMessages[status])}: `}
                  </strong>
                  <span data-testid="status-message">{statusMessage}</span>
                </>
              )}
            </span>
            {feesValue && (
              <span>
                <strong data-testid="fee-title">{`${feesTitle}: `}</strong>
                <span data-testid="fee-message">{feesValue}</span>
              </span>
            )}
          </div>
        </Icon>
      </div>
      {bookshelfFlag?.isActive && !isNonCatalogItem && (
        <div className={css.buttonsWrapper}>
          <Button styleType="link" onClick={handleButtonClick}>
            {actionButtonText}
          </Button>
        </div>
      )}
      {isRemoveHoldError && (
        <div className={css.errorWrapper}>
          <ModalNotification
            type="error"
            modalNotifications={{
              error: getErrorNotificationProps({
                title: (
                  <FormattedMessage
                    id="itemStatus.unableToCancel"
                    defaultMessage="We were unable to remove this hold."
                  />
                ),
                message: errorMessage,
              }),
            }}
            onCloseButtonClick={onErrorClose}
          />
        </div>
      )}
    </div>
  );
};
