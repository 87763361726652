import { FC, ReactElement, ReactNode } from 'react';
import { Field, Form } from 'react-final-form';
import { useIntl } from 'react-intl';

import {
  Button,
  createBemBlockBuilder,
  IndeterminateSpinner,
} from '@ebsco-ui/ebsco-ui';

import { TextField, PasswordField } from '@app/components';
import { useValidator } from '@app/hooks';
import { sharedMessages } from '@app/translations';

import '../AuthPageWrapper/AuthPageWrapper.scss';

const cnBem = createBemBlockBuilder(['authPageWrapper']);

export interface FieldDescription {
  label: string | ReactElement;
  name: string;
  isPasswordField?: boolean;
}

interface AuthFormProps {
  fields: FieldDescription[];
  additionalFields?: ReactNode;
  passwordResetLink?: ReactNode;
  isLoading?: boolean;
  shouldDisplayFields?: boolean;
  onSubmit: (values, form) => void;
}

export const AuthForm: FC<AuthFormProps> = ({
  fields,
  additionalFields,
  passwordResetLink,
  isLoading,
  shouldDisplayFields = true,
  onSubmit,
}) => {
  const { $t } = useIntl();
  const { validateRequiredField } = useValidator();

  return (
    <Form
      render={({ handleSubmit, submitting, pristine, invalid }) => (
        <form className={cnBem('__form')} onSubmit={handleSubmit}>
          {shouldDisplayFields && (
            <>
              {isLoading && (
                <div data-testid="spinner">
                  <IndeterminateSpinner
                    level="component"
                    className={cnBem('__spinner')}
                  />
                </div>
              )}
              {fields.map(field => (
                <div className={cnBem('__row')} key={field.name}>
                  <Field
                    label={field.label}
                    name={field.name}
                    component={
                      field.isPasswordField ? PasswordField : TextField
                    }
                    validate={validateRequiredField}
                  />
                  {field.isPasswordField && passwordResetLink}
                </div>
              ))}
              <div className={cnBem('__row')}>
                <Button
                  className={cnBem('__submitButton')}
                  styleType="solid"
                  type="submit"
                  disabled={invalid || submitting || pristine}
                >
                  {$t(sharedMessages.signIn)}
                </Button>
              </div>
            </>
          )}
          {additionalFields}
        </form>
      )}
      onSubmit={onSubmit}
    />
  );
};
