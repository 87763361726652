import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { setAxiosHeaders } from '@app/utils';
import { USER_SESSION } from '@app/constants';

import { useSessionValidation, PatronSession } from './requests';

export type authenticateToken = (token: string, redirectRoute: string) => void;

interface AppUsersProps {
  patronSession: PatronSession | null;
  isConsortiaLogin: boolean;
  setPatronSession: Dispatch<SetStateAction<PatronSession | null>>;
}

interface UserState {
  isPatronUserLoggedIn: boolean;
  isGuestUserLoggedIn: boolean;
  isOkapiDown: boolean;
  authenticateToken: authenticateToken;
}

export const useAppUsers = ({
  patronSession,
  isConsortiaLogin,
  setPatronSession,
}: AppUsersProps): UserState => {
  const [isOkapiSessionDown, setIsOkapiSessionDown] = useState(false);
  const [isPatronUserLoggedIn, setIsPatronUserLoggedIn] = useState(false);
  const patronUser = useSessionValidation({
    userSessionKey: USER_SESSION.patron,
    isConsortiaLogin,
    setIsOkapiSessionDown,
    setPatronSession,
  });
  const guestUser = useSessionValidation({
    userSessionKey: USER_SESSION.guest,
    setIsOkapiSessionDown,
  });

  useEffect(() => {
    if (patronUser.isLoggedIn && Boolean(patronUser.session?.user)) {
      setAxiosHeaders(patronUser.session);
      setPatronSession(patronUser.session);
      setIsPatronUserLoggedIn(true);
    } else if (
      guestUser.isLoggedIn &&
      guestUser.session?.token &&
      !isPatronUserLoggedIn
    ) {
      setAxiosHeaders(guestUser.session);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestUser.isLoggedIn, patronUser.isLoggedIn, setPatronSession]);

  const authenticateToken: authenticateToken = (token, redirectRoute) => {
    patronUser.validateToken({ token, redirectRoute });
  };

  useEffect(() => {
    setIsPatronUserLoggedIn(Boolean(patronSession?.user));
  }, [patronSession?.user]);

  return {
    isPatronUserLoggedIn,
    isGuestUserLoggedIn: guestUser.isLoggedIn,
    isOkapiDown: isOkapiSessionDown,
    authenticateToken,
  };
};
