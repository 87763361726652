import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useAppContext } from '@app/contexts';
import { axios } from '@app/utils';

import { HoldActionErrorDto, PlaceHoldDto } from '.';

interface RenewRequestProps {
  itemId: string;
}

interface RenewDto extends PlaceHoldDto {
  dueDate: string;
  overdue: boolean;
}

export const useRenewMutation = (): UseMutationResult<
  RenewDto,
  HoldActionErrorDto,
  RenewRequestProps
> => {
  const appContext = useAppContext();

  return useMutation(({ itemId }: RenewRequestProps) => {
    const userId = appContext?.patronSession?.user?.externalSystemId;
    const patronPath = `/opac-patron/account/${userId}`;
    const renewPath = `/item/${itemId}/renew`;

    return axios
      .post<RenewDto>(`${patronPath}${renewPath}`)
      .then(response => response.data);
  });
};
