import { ChangeEvent, FC, useState, useCallback } from 'react';
import { difference, compact, values, without, sumBy } from 'lodash';

import { FACET, SelectedFacets } from '@app/search';
import { useMappingsContext, useSearchContext } from '@app/contexts';
import { useFeatureFlag } from '@app/hooks';
import { FEATURE } from '@app/constants';

import {
  FilterPanelContent,
  Facet,
  FacetAttribute,
  FACETS_ORDER,
  useFacetsRequest,
} from '..';

type FacetKeys = (typeof FACETS_ORDER)[0];

export type SelectedFacetAttributes = Partial<
  Record<FacetKeys, Record<string, boolean>>
>;

export const formatSelectedFacets = (
  facets: SelectedFacetAttributes
): SelectedFacets =>
  Object.keys(facets).reduce((selectedFacets, currentFacet) => {
    Object.keys(facets[currentFacet]).forEach(facetItem => {
      if (facets[currentFacet][facetItem]) {
        if (!Array.isArray(selectedFacets[currentFacet])) {
          selectedFacets[currentFacet] = [];
        }

        selectedFacets[currentFacet].push(facetItem);
      }
    });

    return selectedFacets;
  }, {});

export const FilterPanel: FC = () => {
  const statusSearchFacetFlag = useFeatureFlag(FEATURE.statusSearchFacet);
  const facetsOrder = statusSearchFacetFlag?.isActive
    ? FACETS_ORDER
    : without(FACETS_ORDER, FACET.STATUS);

  const [expandedFacets, setExpandedFacets] = useState<FacetKeys[]>([]);
  const [facetsToFetch, setFacetsToFetch] = useState<FacetKeys[]>([]);
  const [isStatePristine, setStatePristine] = useState(true);
  const { selectedFacets, setSelectedFacets } = useSearchContext();
  const { locations } = useMappingsContext();

  const facetGroups = useFacetsRequest({
    expandedFacets: facetsToFetch,
    isStatePristine,
  });

  const getFacetSelectionCount = useCallback(
    facetKey => compact(values(selectedFacets[facetKey])).length,
    [selectedFacets]
  );

  const selectedFiltersCount = sumBy(facetsOrder, facetKey =>
    getFacetSelectionCount(facetKey)
  );

  const toggleFacetAttribute = useCallback(
    (facetKey, value) => {
      setSelectedFacets({
        ...selectedFacets,
        [facetKey]: {
          ...selectedFacets[facetKey],
          [value]: !selectedFacets[facetKey][value],
        },
      });
    },
    [selectedFacets, setSelectedFacets]
  );

  const isFacetAttributeSelected = useCallback(
    (facetKey, value) => Boolean(selectedFacets[facetKey]?.[value]),
    [selectedFacets]
  );

  const generateFacetAttributes = useCallback(
    (facetKey, facetValues) =>
      facetValues?.map(({ label, value, count }) => (
        <FacetAttribute
          key={`${facetKey}-${value}`}
          label={label}
          value={value}
          selectedCount={count}
          isChecked={isFacetAttributeSelected(facetKey, value)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            toggleFacetAttribute(facetKey, e.target.value)
          }
        />
      )),
    [toggleFacetAttribute, isFacetAttributeSelected]
  );

  const expandFacet = useCallback(
    (facetKey: (typeof facetsOrder)[0]) => {
      const wasFacetExpandedBefore = facetsToFetch.includes(facetKey);

      if (!wasFacetExpandedBefore) {
        setStatePristine(false);
        setFacetsToFetch(prevState => prevState.concat(facetKey));
      }

      setExpandedFacets([...expandedFacets, facetKey]);
    },
    [expandedFacets, facetsToFetch]
  );

  const closeFacet = useCallback(
    (facetKey: (typeof facetsOrder)[0]) => {
      const filteredFacets = difference(expandedFacets, [facetKey]);

      if (!getFacetSelectionCount(facetKey)) {
        setFacetsToFetch(prevState => difference(prevState, [facetKey]));
      }

      setExpandedFacets(filteredFacets);
    },
    [getFacetSelectionCount, expandedFacets]
  );

  const renderFacets = useCallback(() => {
    return facetsOrder.map(facetKey => {
      const {
        label,
        values: facetValues,
        isError = false,
      } = facetGroups[facetKey];
      const selectedCount = getFacetSelectionCount(facetKey);
      const isFacetExpanded = selectedCount > 0;
      const isLocationFacet = facetKey === 'items.effectiveLocationId';
      const isFacetActive = isLocationFacet ? Boolean(locations) : true;

      return (
        <Facet
          key={facetKey}
          label={label}
          facetKey={facetKey}
          selectedCount={selectedCount}
          facetAttributes={generateFacetAttributes(facetKey, facetValues)}
          isInitiallyExpanded={isFacetExpanded}
          isError={isError}
          isActive={isFacetActive}
          expandFacet={expandFacet}
          closeFacet={closeFacet}
        />
      );
    });
  }, [
    facetsOrder,
    facetGroups,
    getFacetSelectionCount,
    locations,
    generateFacetAttributes,
    expandFacet,
    closeFacet,
  ]);

  return (
    <FilterPanelContent
      selectedFiltersCount={selectedFiltersCount}
      facets={facetGroups}
      renderFacets={renderFacets}
      toggleFacetAttribute={toggleFacetAttribute}
    />
  );
};
