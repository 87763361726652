import { FC } from 'react';
import { uniqBy } from 'lodash';

import { FeatureFlag, FeatureFlagsContext } from '@app/contexts';

import featureConfig from '../../feature-config.json';

interface FeatureFlagsProviderProps {
  flags?: FeatureFlag[];
}

const formatFeatureFlags = (flags: FeatureFlag[] = []): FeatureFlag[] => {
  const { features } = featureConfig;

  return uniqBy(flags.concat(features as FeatureFlag[]), 'name');
};

export const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({
  flags,
  children,
}) => {
  return (
    <FeatureFlagsContext.Provider value={formatFeatureFlags(flags)}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
