import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { noop } from 'lodash';

import { useAppContext } from '@app/contexts';
import { APIError, axios, setAxiosHeaders } from '@app/utils';
import { INVOKE_PATH_PREFIX, ROUTE, USER_SESSION } from '@app/constants';

import { useQueryParams } from '..';

interface DirectLoginRequestBody {
  password: string;
  username: string;
}

interface LoginRequestBody {
  barcode: string;
  pin: string;
}

export interface UserInfo {
  externalSystemId: string;
}

export interface UserPersonalInfo {
  lastName: string;
  firstName: string;
  email: string;
}

export interface PatronSession {
  token: string;
  user?: UserInfo & Partial<UserPersonalInfo>;
}

interface UserMetadata {
  user: { personal?: UserPersonalInfo } & UserInfo;
}

type processPatronSession = (
  token: string,
  data: UserMetadata,
  customRedirectRoute?: string
) => void;

interface LoginUtils {
  directLoginMutation: UseMutationResult;
  loginMutation: UseMutationResult;
  processPatronSession: processPatronSession;
}

interface UseLoginProps {
  isConsortiaLogin?: boolean;
  setPatronSession?: Dispatch<SetStateAction<PatronSession>>;
}

export const useLogin = ({
  isConsortiaLogin = false,
  setPatronSession = noop,
}: UseLoginProps = {}): LoginUtils => {
  const navigate = useNavigate();
  const { redirect } = useQueryParams();
  const appContext = useAppContext();

  const redirectRoute = redirect ? `/${redirect}` : '';

  const processPatronSession: processPatronSession = (
    token,
    data,
    customRedirectRoute = ''
  ) => {
    const patronSession = {
      token,
      user: {
        externalSystemId: data.user.externalSystemId,
        ...data.user?.personal,
      },
    };

    sessionStorage.setItem(USER_SESSION.patron, JSON.stringify(patronSession));
    setAxiosHeaders(patronSession);

    if (appContext?.setPatronSession) {
      appContext.setPatronSession(patronSession);
    } else {
      setPatronSession(patronSession);
    }

    navigate(customRedirectRoute || redirectRoute || ROUTE.search);
  };

  const directLoginMutation = useMutation((values: DirectLoginRequestBody) =>
    axios
      .post('/bl-users/login', {
        ...values,
      })
      .then(response => {
        processPatronSession(response.headers['x-okapi-token'], response.data);
      })
      .catch(error => {
        if (error.response.data) {
          throw new APIError(
            error.response.data.errorMessage ||
              error.response.data.errors?.[0]?.message ||
              error.response.data
          );
        }

        throw new Error(error.message);
      })
  );

  const loginMutation = useMutation((values: LoginRequestBody) => {
    const pathPrefix = isConsortiaLogin ? INVOKE_PATH_PREFIX : '';

    return axios
      .post(`${pathPrefix}/opac-auth/login`, values, {
        withCredentials: isConsortiaLogin,
      })
      .then(response => {
        appContext.authenticateToken(
          response.data.okapiToken,
          redirectRoute || ROUTE.search
        );
      })
      .catch(error => {
        if (error.response.data) {
          throw new APIError(
            error.response.data.message || error.response.data
          );
        }

        throw new Error(error.message);
      });
  });

  return {
    directLoginMutation,
    loginMutation,
    processPatronSession,
  };
};
