import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { identity, isEmpty, kebabCase } from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

import {
  createBemBlockBuilder,
  Loading,
  PageErrorMessage,
  ShowMore,
} from '@ebsco-ui/ebsco-ui';

import {
  AvailabilityTable,
  Carousel,
  MetadataDetailsList,
  MetadataItem,
  Novelist,
  RecordMetadata,
  RecordSummary,
  RelatedSubjects,
  SectionWithTitle,
  ShowMoreButton,
  FeatureFlags,
  ExternalLink,
} from '@app/components';
import { ElectronicAccessDto, SearchInstanceDto } from '@app/pages/constants';
import { IDENTIFIER_TYPE } from '@app/search';
import {
  useAppContext,
  useAvailabilityContext,
  useMappingsContext,
  useSearchContext,
} from '@app/contexts';
import {
  useFeatureFlag,
  useInstanceDetailsRoutePath,
  useInstanceRelationships,
  useVirtualShelfFetch,
} from '@app/hooks';
import { axios, formatArrayToString } from '@app/utils';
import {
  langMessages,
  noteTypesMessages,
  sharedMessages,
} from '@app/translations';
import { ROUTE, FEATURE } from '@app/constants';

import { getItemStatusData, getItemWithStatus } from '../utils';

import '../../components/Record/RecordMetadata/RecordMetadata.scss';
import css from './InstanceDetailsPage.module.scss';

const cnBem = createBemBlockBuilder(['recordMetadata']);
const notesLimit = 1;
const childInstancesLimit = 5;

export const InstanceDetailsPage: FC = () => {
  const intl = useIntl();
  const { $t } = intl;
  const { getAvailability, availability, isAvailabilityLoading } =
    useAvailabilityContext();
  const { locale } = useAppContext();
  const virtualShelfFlag = useFeatureFlag(FEATURE.virtualBookshelf);
  const staffViewFlag = useFeatureFlag(FEATURE.staffView);
  const { userItemStatuses, noteTypes } = useMappingsContext();
  const { setIsPageFromBackButton } = useSearchContext();
  const { id = '' } = useParams();
  const { getInstanceDetailsRoutePath } = useInstanceDetailsRoutePath();
  const [browseShelfOrder, setBrowseShelfOrder] = useState<string | undefined>(
    ''
  );
  const {
    relatedItems,
    virtualShelfQuery: { isInitialLoading: isVirtualShelfLoading },
  } = useVirtualShelfFetch(
    virtualShelfFlag?.isActive ? browseShelfOrder : undefined
  );
  const [metadata, setMetadata] = useState<MetadataItem[]>([]);

  const instanceDetailsQuery = useQuery<SearchInstanceDto>(
    ['instanceDetails', { id }],
    async () =>
      axios
        .get(`/opac-inventory/instances/${id}`, {
          params: {
            withHoldingAndItemData: true,
          },
        })
        .then(response => response.data)
  );

  const { data: instanceData } = instanceDetailsQuery;

  const { search } = getInstanceDetailsRoutePath(id);

  useEffect(() => {
    if (instanceData && !isEmpty(instanceData)) {
      setBrowseShelfOrder(instanceData.effectiveShelvingOrder);
    }
  }, [instanceData]);

  const childInstances = useInstanceRelationships(instanceData?.childInstances);

  const isDataFetched =
    instanceData && !isEmpty(instanceData) && !isEmpty(metadata);

  const getPublishers = ({ publication }) => {
    return publication
      .filter(({ publisher }) => Boolean(publisher))
      .map(({ publisher }) => publisher);
  };

  const formatElectronicAccess = (electronicAccess: ElectronicAccessDto[]) => {
    return (
      <ul className={css.onlineAccess}>
        {electronicAccess.map(({ linkText, uri }) => (
          <li key={uri} className={css.onlineAccessLink}>
            <ExternalLink href={uri}>
              <FontAwesomeIcon
                icon={faExternalLinkSquareAlt}
                className={css.icon}
              />
              {linkText || uri}
            </ExternalLink>
          </li>
        ))}
      </ul>
    );
  };

  const formatStaffView = useCallback(() => {
    return (
      <FormattedMessage
        id="instanceDetails.marcRecord"
        defaultMessage="MARC record"
      >
        {message => (
          <Link
            className="emphasizedLink"
            to={`${generatePath(ROUTE.staffView, { id })}?${search}`}
          >
            {message[0]}
          </Link>
        )}
      </FormattedMessage>
    );
  }, [search, id]);

  useEffect(() => {
    const handlePopState = () => {
      setIsPageFromBackButton(true);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setIsPageFromBackButton]);

  const shelfLoadingMessage = $t({
    id: 'shelf.loading',
    defaultMessage: 'Loading shelf',
  });

  const instanceMetadataElements = useMemo(
    () => [
      {
        title: $t({
          id: 'instanceDetails.title',
          defaultMessage: 'Title',
        }),
        getData: instance => instance.title,
      },
      {
        title: $t({
          id: 'instanceDetails.source',
          defaultMessage: 'Source',
        }),
        getData: instance => instance.source,
      },
      {
        title: $t({
          id: 'instanceDetails.language',
          defaultMessage: 'Language',
        }),
        getData: instance => {
          const filteredLanguage = instance.languages
            ?.filter(language => langMessages[language])
            .map(language => $t(langMessages[language]));

          return !isEmpty(filteredLanguage)
            ? filteredLanguage
            : [$t(langMessages.undefined)];
        },
        formatter: formatArrayToString,
      },
      {
        title: $t({
          id: 'instanceDetails.publisher',
          defaultMessage: 'Publisher',
        }),
        getData: getPublishers,
        formatter: data => <MetadataDetailsList details={data} />,
      },
      {
        title: $t({
          id: 'instanceDetails.physicalDescription',
          defaultMessage: 'Physical description',
        }),
        getData: instance => instance.physicalDescriptions,
        formatter: formatArrayToString,
      },
      {
        title: $t({
          id: 'instanceDetails.documentType',
          defaultMessage: 'Document Type',
        }),
        getData: instance => instance.sourceTypes,
        formatter: formatArrayToString,
      },
      {
        title: IDENTIFIER_TYPE.ISBN,
        getData: instance => instance.isbns,
        formatter: data => <MetadataDetailsList details={data} />,
      },
      {
        title: IDENTIFIER_TYPE.ISSN,
        getData: instance => instance.issns,
        formatter: data => <MetadataDetailsList details={data} />,
      },
      {
        title: IDENTIFIER_TYPE.LCCN,
        getData: instance => instance.lccns,
        formatter: data => <MetadataDetailsList details={data} />,
      },
      {
        title: IDENTIFIER_TYPE.OCLC,
        getData: instance => instance.oclcs,
        formatter: data => <MetadataDetailsList details={data} />,
      },
      {
        title: $t(sharedMessages.staffView),
        getData: instance =>
          staffViewFlag?.isActive && instance.source === 'MARC'
            ? instance.source
            : null,
        formatter: formatStaffView,
      },
      {
        title: $t({
          id: 'instanceDetails.edition',
          defaultMessage: 'Edition',
        }),
        getData: instance => instance.editions,
        formatter: formatArrayToString,
      },
    ],
    [$t, formatStaffView, staffViewFlag?.isActive]
  );

  const sectionElements = useMemo(
    () => [
      {
        title: $t({
          id: 'instanceDetails.onlineAccess',
          defaultMessage: 'Online access',
        }),
        getData: instance => instance.electronicAccess,
        formatter: formatElectronicAccess,
      },
      {
        title: $t({
          id: 'instanceDetails.browseShelf',
          defaultMessage: 'Browse this shelf',
        }),
        shouldRender:
          virtualShelfFlag?.isActive &&
          (isVirtualShelfLoading || !isEmpty(relatedItems)),
        getData: () => ({ relatedItems, isVirtualShelfLoading }),
        formatter: data =>
          data.isVirtualShelfLoading ? (
            <Loading loadingMessage={shelfLoadingMessage} />
          ) : (
            <Carousel items={data.relatedItems} />
          ),
        testId: 'browseShelf',
      },
      {
        title: $t({
          id: 'instanceDetails.relatedSubjects',
          defaultMessage: 'Related subjects',
        }),
        getData: instance => instance.subjects,
        formatter: data => <RelatedSubjects subjects={data} />,
      },
      {
        title: $t({
          id: 'instanceDetails.recordsInCollection',
          defaultMessage: 'Records in the collection',
        }),
        getData: () => childInstances,
        testId: 'childInstances',
        formatter: data => (
          <ShowMore limit={childInstancesLimit}>
            <ul>
              <ShowMore.Items>
                {data.map(({ id: instanceId, title }) => (
                  <li key={instanceId}>
                    <Link
                      to={getInstanceDetailsRoutePath(instanceId)}
                      key={title}
                      className="underlinedLink"
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ShowMore.Items>
            </ul>
            <ShowMoreButton
              showMoreLimit={childInstancesLimit}
              dataLength={data.length}
            />
          </ShowMore>
        ),
      },
      {
        ariaLabel: $t({
          id: 'instanceDetails.notes',
          defaultMessage: 'Record notes',
        }),
        testId: 'notes',
        formatter: data => (
          <ShowMore limit={notesLimit} className={css.showMore}>
            <ul>
              <ShowMore.Items>
                {data.map(({ labelKey, note }) => (
                  <li key={labelKey}>
                    <FormattedMessage
                      tagName="h3"
                      {...noteTypesMessages[labelKey]}
                    />
                    <div>
                      {note.map(content => (
                        <Fragment key={content}>
                          <span>{content}</span>
                          <br />
                        </Fragment>
                      ))}
                    </div>
                  </li>
                ))}
              </ShowMore.Items>
            </ul>
            <ShowMoreButton
              showMoreLimit={notesLimit}
              dataLength={data.length}
            />
          </ShowMore>
        ),
        getData: instance =>
          instance.notes?.filter(({ labelKey }) =>
            Boolean(noteTypes?.[labelKey])
          ),
      },
    ],
    [
      virtualShelfFlag?.isActive,
      $t,
      isVirtualShelfLoading,
      relatedItems,
      shelfLoadingMessage,
      childInstances,
      getInstanceDetailsRoutePath,
      noteTypes,
    ]
  );

  const formatInstanceMetadata = useCallback(
    instance =>
      instanceMetadataElements.reduce<MetadataItem[]>(
        (newInstance, { title, formatter, getData }) => {
          const data = getData(instance);

          if (!isEmpty(data)) {
            newInstance.push({
              title,
              content: (formatter || identity)(data),
            });
          }

          return newInstance;
        },
        []
      ),
    [instanceMetadataElements]
  );

  const availabilityLoadingMessage = $t({
    id: 'availability.loading',
    defaultMessage: 'Checking availability',
  });

  useEffect(() => {
    if (!isEmpty(instanceData)) {
      setMetadata(formatInstanceMetadata(instanceData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceData]);

  useEffect(() => {
    if (!availability[id]) {
      getAvailability([id]);
    }
  }, [id, availability, getAvailability]);

  const itemStatusData = useMemo(() => {
    const itemWithStatus =
      instanceData && getItemWithStatus(instanceData.id, userItemStatuses);

    return itemWithStatus && getItemStatusData(itemWithStatus, intl, locale);
  }, [instanceData, intl, locale, userItemStatuses]);

  const getPageContent = () => {
    const { isError, isLoading, refetch } = instanceDetailsQuery;

    if (isLoading) {
      return (
        <main data-testid="spinner">
          <Loading loadingMessage={$t(sharedMessages.loadingMessage)} />
        </main>
      );
    }

    if (isError) {
      return (
        <main>
          <PageErrorMessage
            className={css.pageErrorMessage}
            buttonText={$t(sharedMessages.refreshPage)}
            title={$t(sharedMessages.pageUnavailable)}
            text={$t(sharedMessages.searchUnavailableText)}
            titleTag="h1"
            onClick={() => refetch()}
          />
        </main>
      );
    }

    const recordAvailability = availability[id];
    const shouldDisplayAvailabilityTable =
      !isEmpty(recordAvailability?.availabilityData) &&
      recordAvailability?.summaryAvailability.isRTACApplicable;

    if (isDataFetched) {
      return (
        <>
          <Helmet>
            <title>
              {$t(
                {
                  id: 'instanceDetails.documentTitle',
                  defaultMessage: '{title} - Record details - EBSCO Locate',
                },
                { title: instanceData.title }
              )}
            </title>
          </Helmet>
          <main data-testid="instance-details-container">
            <RecordSummary
              instance={instanceData}
              itemStatusData={itemStatusData}
              availability={recordAvailability?.summaryAvailability}
              isAvailabilityLoading={isAvailabilityLoading}
              availabilityLoadingMessage={availabilityLoadingMessage}
              placement="instanceDetails"
              testId="record-summary"
            />
            {isAvailabilityLoading ? (
              <div className={css.availabilityLoading}>
                <Loading loadingMessage={availabilityLoadingMessage} />
              </div>
            ) : (
              shouldDisplayAvailabilityTable && (
                <SectionWithTitle
                  title={$t({
                    id: 'instanceDetails.availabilityLocations',
                    defaultMessage: 'Availability & locations',
                  })}
                >
                  <AvailabilityTable
                    records={recordAvailability.availabilityData}
                    maxAmountToDisplay={3}
                  />
                </SectionWithTitle>
              )
            )}
            {sectionElements.map(
              ({
                title,
                ariaLabel,
                shouldRender = true,
                testId = kebabCase(title),
                formatter = identity,
                getData,
              }) =>
                shouldRender &&
                !isEmpty(getData(instanceData)) && (
                  <SectionWithTitle
                    title={title}
                    ariaLabel={ariaLabel}
                    key={title ?? ariaLabel}
                    testId={testId}
                  >
                    <div
                      className={cn(
                        cnBem('__content'),
                        cnBem(`__${testId}Content`),
                        {
                          [cnBem('__content--fullWidth')]:
                            testId === 'browseShelf',
                        }
                      )}
                      dir="ltr"
                      data-testid={`record-${testId}-content`}
                    >
                      {formatter(getData(instanceData))}
                    </div>
                  </SectionWithTitle>
                )
            )}
            <div
              className={css.additionalInfo}
              data-testid="additional-information"
            >
              <SectionWithTitle
                title={$t({
                  id: 'instanceDetails.additionalInformation',
                  defaultMessage: 'Additional information',
                })}
              >
                <RecordMetadata items={metadata} />
              </SectionWithTitle>
            </div>
          </main>
          <FeatureFlags
            authorizedFlags={[FEATURE.novelist]}
            renderOn={flags => (
              <Novelist
                isbn={instanceData?.isbns?.[0]}
                config={flags[0].config}
              />
            )}
          />
        </>
      );
    }

    return null;
  };

  return (
    <div
      data-testid="instance-details-page-wrapper"
      className={cn(css.wrapper, 'container')}
    >
      {getPageContent()}
    </div>
  );
};
