import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  getErrorNotificationProps,
  ModalNotification,
  ModalNotificationType,
} from '@app/components';
import { NOTIFICATION_STATUS } from '@app/constants';

interface RenewNotificationProps {
  type: ModalNotificationType;
  statusMessage: string;
  errorMessage?: string;
}

export const RenewNotification: FC<RenewNotificationProps> = ({
  type,
  statusMessage,
  errorMessage = '',
}) => {
  const modalNotifications = {
    success: {
      type: NOTIFICATION_STATUS.success,
      header: (
        <FormattedMessage
          id="renewModal.checkoutRenewed"
          defaultMessage="Your checkout has been renewed"
        />
      ),
      children: (
        <FormattedMessage
          id="renewModal.expires"
          defaultMessage="{statusMessage}."
          values={{ statusMessage }}
        />
      ),
    },
    error: getErrorNotificationProps({
      title: (
        <FormattedMessage
          id="renewModal.unableToRenew"
          defaultMessage="We were unable to renew this book."
        />
      ),
      message: errorMessage,
    }),
  };

  return (
    <ModalNotification type={type} modalNotifications={modalNotifications} />
  );
};
