import { FC, useEffect, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  AuthRoute,
  ConditionalRoute,
  ProviderRoot,
  ThemingConfig,
} from '@app/components';
import {
  DirectLoginPage,
  EnterBarcodePage,
  EnterNewPinPage,
  LoginPage,
  LogoutPage,
  MainPage,
  SSOPage,
} from '@app/pages';
import { PatronSession, useAppUsers, useLocale } from '@app/hooks';
import { FEATURE, ROUTE } from '@app/constants';

import featureConfig from './feature-config.json';

enum APP_STATE {
  CREATED = 'CREATED',
  SERVER_DOWN = 'SERVER_DOWN',
  READY = 'READY',
}

export const App: FC = () => {
  const [appState, setAppState] = useState<APP_STATE>(APP_STATE.CREATED);
  const [patronSession, setPatronSession] = useState<PatronSession | null>(
    null
  );
  const [holdRecordId, setHoldRecordId] = useState('');
  const { locale, translationMessages, setSelectedLocale } = useLocale();

  const {
    isPatronUserLoggedIn,
    isGuestUserLoggedIn,
    isOkapiDown,
    authenticateToken,
  } = useAppUsers({
    patronSession,
    isConsortiaLogin: Boolean(
      featureConfig.features.find(
        feature => feature.name === FEATURE.consortiaLoginFlow
      )?.isActive
    ),
    setPatronSession,
  });

  useEffect(() => {
    if (isOkapiDown) {
      setAppState(APP_STATE.SERVER_DOWN);

      return;
    }

    if (isPatronUserLoggedIn || isGuestUserLoggedIn) {
      setAppState(APP_STATE.READY);
    }
  }, [isPatronUserLoggedIn, isGuestUserLoggedIn, isOkapiDown]);

  if (appState === APP_STATE.SERVER_DOWN) {
    return (
      <IntlProvider locale={locale} messages={translationMessages}>
        <FormattedMessage
          tagName="div"
          id="serverIsDown"
          defaultMessage="Server is down"
        />
      </IntlProvider>
    );
  }

  if (appState !== APP_STATE.READY) {
    return null;
  }

  return (
    <ProviderRoot
      appContext={{
        locale,
        isLoggedIn: isPatronUserLoggedIn,
        patronSession,
        holdRecordId,
        setSelectedLocale,
        setPatronSession,
        setHoldRecordId,
        authenticateToken,
      }}
      messages={translationMessages}
    >
      <ThemingConfig />
      <Routes>
        <Route
          path={ROUTE.login}
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route
          path={ROUTE.directLogin}
          element={
            <AuthRoute>
              <DirectLoginPage />
            </AuthRoute>
          }
        />
        <Route
          path={ROUTE.logout}
          element={
            <ConditionalRoute flags={[FEATURE.account]}>
              <LogoutPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={ROUTE.sso}
          element={
            <AuthRoute>
              <SSOPage />
            </AuthRoute>
          }
        />
        <Route
          path={ROUTE.enterNewPin}
          element={
            <AuthRoute>
              <EnterNewPinPage />
            </AuthRoute>
          }
        />
        <Route
          path={ROUTE.resetPinRoot}
          element={<Navigate replace to={ROUTE.enterBarcode} />}
        />
        <Route
          path={ROUTE.enterBarcode}
          element={
            <AuthRoute>
              <EnterBarcodePage />
            </AuthRoute>
          }
        />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </ProviderRoot>
  );
};
